export const TTF_TEAM = 'ttf_team'
export const TF_DEVELOPER = 'tf_developer'
export const TF_SUPPORT_ADMIN = 'tf_support_admin'
export const TF_CLIENT_SUPPORT_ADMIN = 'tf_client_support_admin'
export const TTF_SUPPORT_ADVANCED = 'ttf_support_advanced'
export const TTF_SUPPORT = 'ttf_support'
export const TF_SUPPORT = 'tf_support'
export const TF_CLIENT_SUPPORT = 'tf_client_support'

export const permissionRuleOne = [
    TTF_TEAM,
    TF_DEVELOPER,
    TF_SUPPORT_ADMIN,
    TF_CLIENT_SUPPORT_ADMIN,
    TTF_SUPPORT_ADVANCED,
]

export const pathnamesOne = [
    "/",
    "/create-account",
    "/permissions",
    "/brands",
    "/incoming-enquiries",
    "/promoter-discount-links",
]

export const permissionRuleTwo = [
    TTF_TEAM,
    TF_DEVELOPER,
    TF_SUPPORT_ADMIN,
    TF_CLIENT_SUPPORT_ADMIN,
]

export const pathnamesTwo = [
    "/completed-events",
    "/event-alternative-views",
    "/co-brands",
    "/ticket-swapper",
    "/disputes",
]

export const permissionRuleThree = [
    TTF_TEAM,
    TF_DEVELOPER,
    TF_SUPPORT_ADMIN,
    TF_CLIENT_SUPPORT_ADMIN,
    TTF_SUPPORT,
    TF_SUPPORT,
    TF_CLIENT_SUPPORT,
    TTF_SUPPORT_ADVANCED,
]

export const pathnamesThree = [
    "/id-verification-status",
    "/user-management",
]

export const permissionRuleFour = [
    TF_CLIENT_SUPPORT_ADMIN,
]

export const pathnamesFour = [
    "brand-verification",
]

export const permissionRuleFive = [
    TTF_TEAM,
    TF_DEVELOPER,
    TF_SUPPORT_ADMIN,
    TF_CLIENT_SUPPORT_ADMIN,
    TTF_SUPPORT,
    TF_SUPPORT,
    TF_CLIENT_SUPPORT,
]

export const pathnamesFive = [
    "/session-management"
]

export const redirectToHomePageEventsRoles = [
    'ttf_team',
    'tf_developer',
    'tf_support_admin',
    'tf_client_support_admin',
    'ttf_support_advanced',
]

export const redirectToIdVerificationStatusRoles = [
    'ttf_support',
    'tf_support',
    'tf_client_support',
]

export const permissionRuleSix = [
    TF_DEVELOPER,
    TF_SUPPORT,
    TF_CLIENT_SUPPORT,
]

export const pathnamesSix = [
    "/client-referrals"
]