
import { Form, useNavigation } from "react-router-dom"

import { AuthFooterText } from "../../components/AuthFooterText"
import { baseAuthFormContainer } from "../../components/AuthForm/AuthForm.css"
import { AuthSubtitle } from "../../components/AuthSubtitle"
import { AuthTitle } from "../../components/AuthTitle"
import { BackgroundEllipse } from "../../components/BackgroundEllipse"
import { customContainerEllipse } from "../../components/BackgroundEllipse/BackgroundEllipse.css"
import { Button } from "../../components/Button"
import { RedirectTextText } from "../../components/ForgotPasswordText"
import { FormField } from "../../components/FormField"
import { showErrorToast } from "../../components/Notification"
import { baseScreenContainer } from "../Header/Header.css"
import { baseHomeContainer } from "./Home.css"

export const catchError = (error: unknown) => {
  if (typeof error === 'string') {
    if (error.includes('invalid drawing (truncated...)')) {
      return showErrorToast(
        'Chart is not configured properly, try to edit it and fix all listed errors in the Seats Io Manager modal'
      )
    }
    return showErrorToast(error.replace('[SEATS_IO] [EXCEPTION] ', ''))
  }
  if (error instanceof Error) {
    return showErrorToast(error.message.replace('[SEATS_IO] [EXCEPTION] ', ''))
  }
  return showErrorToast("Error!")
}

const Home = () => {
  const navigation = useNavigation()
  const isLoading = navigation.state !== "idle"
  return (
    <div className={baseHomeContainer}>
      <BackgroundEllipse className={customContainerEllipse.containerEllipseA} />
      <BackgroundEllipse className={customContainerEllipse.containerEllipseB} />
      <div className={baseScreenContainer}>
        <AuthTitle>Internal Admin</AuthTitle>
        <AuthSubtitle>Log in as Admin</AuthSubtitle>
        <Form
          className={baseAuthFormContainer}
          method="post"
          action="/login"
        >
          <FormField name="email" type="email" />
          <FormField name="password" type="password" />
          <Button type="submit" disabled={isLoading} isLoading={isLoading} size="small" color="white">
            Log In
          </Button>
          <RedirectTextText to={"/reset-password"}>Forgot your password?</RedirectTextText>
        </Form>
        <AuthFooterText>
          © 2050 THETICKETFAIRY All Rights Reserved
        </AuthFooterText>
      </div>
    </div>
  )
}

export default Home
