import { FC, useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"

import { ReactComponent as HomeSVG } from "../../assets/home.svg"
import { ReactComponent as UserSVG } from "../../assets/user.svg"
import { SideBarLink } from "../../components/SideBarLink"
import { AuthResponse } from "../../types/types"
import {
    pathnamesFive,
    pathnamesFour,
    pathnamesOne,
    pathnamesThree,
    pathnamesTwo,
    permissionRuleFive,
    permissionRuleFour,
    permissionRuleOne,
    permissionRuleSix,
    permissionRuleThree,
    permissionRuleTwo,
    redirectToHomePageEventsRoles
} from "../../utils/constants"
import { baseHomeContainer } from "../Home/Home.css"
import { baseInfoBox, customSideBarContainer, customSideBarText } from "./Header.css"

export const SiderMenu: FC<{ auth: AuthResponse }> = ({ auth }) => {
    const [showSideBar, setShowSideBar] = useState(true)
    const tfRole = auth?.data.tfRole || ""
    const isSuperAdmin = auth?.data.isSuperAdmin
    const permissionRulesOne = isSuperAdmin || permissionRuleOne.includes(tfRole)
    const permissionRulesTwo = isSuperAdmin || permissionRuleTwo.includes(tfRole)
    const permissionRulesThree = isSuperAdmin || permissionRuleThree.includes(tfRole)
    const permissionRulesFour = isSuperAdmin || permissionRuleFour.includes(tfRole)
    const permissionRulesFive = isSuperAdmin || permissionRuleFive.includes(tfRole)
    const permissionRulesSix = isSuperAdmin || permissionRuleSix.includes(tfRole)
    const navigate = useNavigate()
    useEffect(() => {
        const { pathname } = new URL(window.location.href)
        const { tfRole, isSuperAdmin } = auth.data
        const defaultPage = redirectToHomePageEventsRoles.includes(tfRole) ? "/" : "/id-verification-status"

        if (tfRole && ["/login", "/reset-password"].includes(pathname)) {
            navigate(defaultPage)
        }

        if (isSuperAdmin) {
            return
        }

        if (!tfRole) {
            throw new Error("Not enough permission")
        }

        if (
            pathnamesOne.includes(pathname) &&
            !permissionRuleOne.includes(tfRole)
        ) {
            navigate(defaultPage)
            return
        }

        if (
            pathnamesTwo.includes(pathname) &&
            !permissionRuleTwo.includes(tfRole)
        ) {
            navigate(tfRole)
            return
        }

        if (
            pathnamesThree.includes(pathname) &&
            !permissionRuleThree.includes(tfRole)

        ) {
            navigate(defaultPage)
            return
        }

        if (
            pathnamesFour.includes(pathname) &&
            !permissionRuleFour.includes(tfRole)
        ) {
            navigate(defaultPage)
            return
        }

        if (
            pathnamesFive.includes(pathname) &&
            !permissionRuleFive.includes(tfRole)
        ) {
            navigate(defaultPage)
            return
        }

        if (
            pathname === "/exclusive-events" &&
            !auth.data.tfMedia
        ) {
            navigate(defaultPage)
            return
        }

    }, [navigate, auth])
    return <div className={baseHomeContainer}>
        <div
            className={
                showSideBar
                    ? customSideBarContainer.open
                    : customSideBarContainer.closed
            }
        >
            <div>
                {permissionRulesOne ? (
                    <SideBarLink
                        title="Home Page Events"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/"
                        icon={(props) => <HomeSVG {...props} />}
                    />
                ) : null}
                {permissionRulesTwo ? (
                    <SideBarLink
                        title="Completed Events"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/completed-events"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {(auth?.data?.tfMedia || auth?.data?.isSuperAdmin) && (
                    <SideBarLink
                        title="Exclusive Events"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/exclusive-events"
                        icon={(props) => <UserSVG {...props} />}
                    />
                )}
                {permissionRulesOne ? (
                    <SideBarLink
                        title="Brands"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/brands"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesTwo ? (
                    <SideBarLink
                        title="Event Alternative Views"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/event-alternative-views"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesThree ? (
                    <SideBarLink
                        title="ID Verification Status"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/id-verification-status"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesTwo ? (
                    <SideBarLink
                        title="Co-Branding/Partnership"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/co-brands"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesTwo ? (
                    <SideBarLink
                        title="Ticket Swapper"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/ticket-swapper"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesTwo ? (
                    <SideBarLink
                        title="Disputes / Chargebacks"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/disputes"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesOne ? (
                    <SideBarLink
                        title="Incoming Enquiries"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/incoming-enquiries"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesFive ? (
                    <SideBarLink
                        title="Session Management"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/session-management"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesTwo ? (
                    <>
                        <SideBarLink
                            title="Seats Io Charts"
                            setShowSideBar={setShowSideBar}
                            showSideBar={showSideBar}
                            to="/seats-io-charts"
                            icon={(props) => <UserSVG {...props} />}
                        />
                        <SideBarLink
                            title="Seats Io Events"
                            setShowSideBar={setShowSideBar}
                            showSideBar={showSideBar}
                            to="/seats-io-events"
                            icon={(props) => <UserSVG {...props} />}
                        />
                        <SideBarLink
                            title="Seats Io Workspaces"
                            setShowSideBar={setShowSideBar}
                            showSideBar={showSideBar}
                            to="/seats-io-workspaces"
                            icon={(props) => <UserSVG {...props} />}
                        />
                    </>
                ) : null}
                {permissionRulesTwo ? <SideBarLink
                    title="Upgrade Page Templates"
                    setShowSideBar={setShowSideBar}
                    showSideBar={showSideBar}
                    to="/upgrade-template"
                    icon={(props) => <UserSVG {...props} />}
                /> : null}
                {auth?.data.isSuperAdmin ? (
                    <SideBarLink
                        title="Id Verification Management"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/bypass-id-verification"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesOne ? (
                    <SideBarLink
                        title="Permissions"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/permissions"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesOne ? (
                    <SideBarLink
                        title="Create Account"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/create-account"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesThree ? (
                    <SideBarLink
                        title="User Management"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/user-management"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {auth?.data?.isSuperAdmin ? (
                    <SideBarLink
                        title="Venues"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/venues"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesFour ? (
                    <SideBarLink
                        title="Brand Verification"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/brand-verification"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesTwo ? <SideBarLink
                    title="Referral Refunds"
                    setShowSideBar={setShowSideBar}
                    showSideBar={showSideBar}
                    to="/referral-refunds"
                    icon={(props) => <UserSVG {...props} />}
                /> : null}
                {permissionRulesOne ? (
                    <SideBarLink
                        title="Promoter Discount Links"
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                        to="/promoter-discount-links"
                        icon={(props) => <UserSVG {...props} />}
                    />
                ) : null}
                {permissionRulesSix ? <SideBarLink
                    title="Client Referrals"
                    setShowSideBar={setShowSideBar}
                    showSideBar={showSideBar}
                    to="/client-referrals"
                    icon={(props) => <UserSVG {...props} />}
                /> : null}
                {permissionRulesThree ? <SideBarLink
                    title="Activity Logs"
                    setShowSideBar={setShowSideBar}
                    showSideBar={showSideBar}
                    to="/activity-logs"
                    icon={(props) => <UserSVG {...props} />}
                /> : null}
                {permissionRulesTwo ? <SideBarLink
                    title="Refund Orders"
                    setShowSideBar={setShowSideBar}
                    showSideBar={showSideBar}
                    to="/refund-orders"
                    icon={(props) => <UserSVG {...props} />}
                /> : null}
            </div>
            {showSideBar ? (
                <div className={baseInfoBox}>
                    <div className={customSideBarText.name}>
                        {auth?.data.firstName + " " + auth?.data.lastName}
                    </div>
                    <div className={customSideBarText.email}>
                        {auth?.data.email}
                    </div>
                </div>
            ) : null}
        </div>
        <Outlet />
    </div>
}