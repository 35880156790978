import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Header } from "../../components/Header"
import { HeaderLink } from "../../components/HeaderLink"
import { deleteCookieByName } from "../../utils/session"

export const ErrorHeaderMenu = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const { pathname } = new URL(window.location.href)
        if (pathname !== '/login') {
            deleteCookieByName("X-TF-ECOMMERCE")
            navigate('/login')
        }
        return
    }, [navigate])
    return <Header>
        <HeaderLink to="/login">Log In</HeaderLink>
    </Header>
}