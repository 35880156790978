import dayjs from "dayjs"
import { lazy, Suspense } from "react"
import { createBrowserRouter, defer, redirect } from "react-router-dom"

import { authApi } from "./api/auth"
import { brandsApi } from "./api/brands"
import { customersApi } from "./api/customers"
import { disputesApi } from "./api/disputes"
import { internalEventsApi, internalEventsConsumerApi } from "./api/events"
import { incomingInquiriesApi } from "./api/inquiries"
import { promoterDiscountLinksApi } from "./api/promoterDiscountLinks"
import { referralsApi } from "./api/referrals"
import { seatsioApi } from "./api/seatsio"
import { sessionsApi } from "./api/sessions"
import { store } from "./api/store"
import { systemEventsApi } from "./api/systemEvents"
import { templatesApi } from "./api/templates"
import { ticketsApi } from "./api/ticket"
import { venuesApi } from "./api/venues"
import { BackgroundEllipse } from "./components/BackgroundEllipse"
import { customContainerEllipse } from "./components/BackgroundEllipse/BackgroundEllipse.css"
import { ActivityLogs } from "./screens/ActivityLogs/index"
import ClientReferrals from './screens/ClientReferrals'
import { ErrorPage } from "./screens/ErrorPage"
import { HeaderAuth } from "./screens/Header"
import { baseHomeContainer } from "./screens/Home/Home.css"
import { catchError } from "./screens/Home/index"
import {
  redirectToHomePageEventsRoles,
  redirectToIdVerificationStatusRoles
} from "./utils/constants"

const CompletedEvents = lazy(() => import("./screens/CompletedEvents/index"))
const ExclusiveEvents = lazy(() => import("./screens/ExclusiveEvents/index"))
const IDVerificationStatus = lazy(
  () => import("./screens/IDVerificationStatus/index")
)
const HomePageEvents = lazy(() => import("./screens/HomePageEvents/index"))
const ResetPassword = lazy(() => import("./screens/ResetPassword/index"))
const Home = lazy(() => import("./screens/Home/index"))
const Brands = lazy(() => import("./screens/Brands/index"))
const EventAlternativeViews = lazy(
  () => import("./screens/EventAlternativeViews/index")
)
const CoBrands = lazy(() => import("./screens/CoBrands/index"))
const TicketSwapper = lazy(() => import("./screens/TicketSwapper/index"))
const Disputes = lazy(() => import("./screens/Disputes/index"))
const SessionManagement = lazy(() => import("./screens/SessionManagement/index"))
const IncomingEnquiries = lazy(() => import("./screens/IncomingEnquiries/index"))
const SeatsIoCharts = lazy(() => import("./screens/SeatsIoCharts/index"))
const SeatsIoWorkspaces = lazy(() => import("./screens/SeatsIoWorkspaces/index"))
const SeatsIoEvents = lazy(() => import("./screens/SeatsIoEvents/index"))
const UpgradeTemplate = lazy(() => import("./screens/UpgradeTemplate/index"))
const Permissions = lazy(() => import("./screens/Permissions/index"))
const IdVerificationManagement = lazy(
  () => import("./screens/IdVerificationManagement/index")
)
const CreateAccount = lazy(() => import("./screens/CreateAccount/index"))
const UserManagement = lazy(() => import("./screens/UserManagement/index"))
const Venues = lazy(() => import("./screens/Venues/index"))
const RefundOrders = lazy(() => import("./screens/RefundOrders"))
const ReferralRefunds = lazy(() => import("./screens/ReferralRefunds/index"))
const BrandVerification = lazy(
  () => import("./screens/BrandVerification/index")
)
const LinkPromoCodeToPromoter = lazy(
  () => import("./screens/LinkPromoCodeToPromoter")
)

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HeaderAuth />,
    errorElement: <ErrorPage />,
    loader: async () => {
      const auth = store.dispatch(authApi.endpoints.getSession.initiate())
      const authResponse = auth.unwrap()
      return defer({
        auth: authResponse,
      })
    },
    children: [
      {
        path: "/login",
        element: (
          <Suspense
            fallback={
              <div className={baseHomeContainer}>
                <BackgroundEllipse
                  className={customContainerEllipse.containerEllipseA}
                />
                <BackgroundEllipse
                  className={customContainerEllipse.containerEllipseB}
                />
              </div>
            }
          >
            <Home />
          </Suspense>
        ),
        loader: () => {
          import("./screens/Home/index")
          return {}
        },
        action: async ({ request }) => {
          try {
            const formData = await request.formData()
            const email = formData.get("email")
            const password = formData.get("password")
            if (typeof password === "string" && typeof email === "string") {
              const logIn = store.dispatch(
                authApi.endpoints.logIn.initiate({ email, password })
              )
              const response = await logIn.unwrap()
              const { tfRole, isSuperAdmin, tfStaff } = response.data
              if (!tfStaff && !isSuperAdmin) {
                catchError('Unauthorized')
                return redirect("/login")
              }
              if (isSuperAdmin || redirectToHomePageEventsRoles.includes(tfRole)) {
                return redirect("/")
              }
              if (redirectToIdVerificationStatusRoles.includes(tfRole)) {
                return redirect("/id-verification-status")
              }
              return redirect('/')
            }
          } catch (error) {
            catchError(error)
            return null
          }
        },
      },
      {
        path: "/reset-password",
        element: (
          <Suspense
            fallback={
              <div className={baseHomeContainer}>
                <BackgroundEllipse
                  className={customContainerEllipse.containerEllipseA}
                />
                <BackgroundEllipse
                  className={customContainerEllipse.containerEllipseB}
                />
              </div>
            }
          >
            <ResetPassword />
          </Suspense>
        ),
        loader: () => {
          import("./screens/ResetPassword/index")
          return {}
        },
      },
      {
        path: "/",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <HomePageEvents />
          </Suspense>
        ),
        loader: () => {
          import("./screens/HomePageEvents/index")
          const params = new URLSearchParams(window.location.search)
          const internalEvents = store.dispatch(
            internalEventsApi.endpoints.getInternalEvents.initiate({
              search_query: params.get("searchText") || "",
              limit: Number(params.get("pageSize")) || 10,
              page: Number(params.get("pageIndex")) + 1,
            })
          )
          return defer({
            internalEvents: internalEvents.unwrap(),
          })
        },
      },
      {
        path: "/completed-events",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <CompletedEvents />
          </Suspense>
        ),
        loader: () => {
          import("./screens/CompletedEvents/index")
          const params = new URLSearchParams(window.location.search)
          const completedEvents = store.dispatch(
            internalEventsApi.endpoints.getCompletedEvents.initiate({
              search_query: params.get("searchText") || "",
              limit: Number(params.get("pageSize")) || 10,
              page: Number(params.get("pageIndex")) + 1,
            })
          )
          return defer({
            completedEvents: completedEvents.unwrap(),
          })
        },
      },
      {
        path: "/exclusive-events",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <ExclusiveEvents />
          </Suspense>
        ),
        loader: () => {
          import("./screens/ExclusiveEvents/index")
          const params = new URLSearchParams(window.location.search)
          const exclusiveEvents = store.dispatch(
            internalEventsApi.endpoints.getExclusiveEvents.initiate({
              brandIds: (params.get("brandIds") || "").split("_"),
              limit: Number(params.get("pageSize")) || 10,
              page: Number(params.get("pageIndex")) + 1,
            })
          )
          return defer({
            exclusiveEvents: exclusiveEvents.unwrap(),
          })
        },
      },
      {
        path: "/brands",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <Brands />
          </Suspense>
        ),
        loader: () => {
          import("./screens/Brands/index")
          const params = new URLSearchParams(window.location.search)
          const brands = store.dispatch(
            brandsApi.endpoints.getBrands.initiate({
              search_query: params.get("searchText") || "",
              limit: Number(params.get("pageSize")) || 10,
              page: Number(params.get("pageIndex")) + 1,
            })
          )
          return defer({
            brands: brands.unwrap(),
          })
        },
      },
      {
        path: "/event-alternative-views",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <EventAlternativeViews />
          </Suspense>
        ),
        loader: () => {
          import("./screens/Brands/index")
          const params = new URLSearchParams(window.location.search)
          const brands = store.dispatch(
            brandsApi.endpoints.getBrands.initiate({
              search_query: params.get("searchText") || "",
              limit: Number(params.get("pageSize")) || 10,
              page: Number(params.get("pageIndex")) + 1,
            })
          )
          return defer({
            brands: brands.unwrap(),
          })
        },
      },
      {
        path: "/co-brands",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <CoBrands />
          </Suspense>
        ),
        loader: () => {
          import("./screens/Brands/index")
          const partnerships = store.dispatch(
            brandsApi.endpoints.getPartnerships.initiate()
          )
          return defer({
            partnerships: partnerships.unwrap(),
          })
        },
      },
      {
        path: "/ticket-swapper",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <TicketSwapper />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/TicketSwapper/index")
          const params = new URLSearchParams(window.location.search)
          const hash = params.get("hash")
          if (hash) {
            const ticket = store.dispatch(
              ticketsApi.endpoints.getTicket.initiate({
                hash,
              })
            )
            return defer({
              ticket: ticket.unwrap(),
            })
          }
          return defer({
            ticket: await Promise.resolve(),
          })
        },
      },
      {
        path: "/disputes",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <Disputes />
          </Suspense>
        ),
        loader: () => {
          import("./screens/Disputes/index")
          const params = new URLSearchParams(window.location.search)
          const disputes = store.dispatch(
            disputesApi.endpoints.getDisputes.initiate({
              search_query: params.get("searchText") || "",
              limit: Number(params.get("pageSize")) || 10,
              page: Number(params.get("pageIndex")) + 1,
            })
          )
          return defer({
            disputes: disputes.unwrap(),
          })
        },
      },
      {
        path: "/session-management",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <SessionManagement />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/SessionManagement/index")
          const params = new URLSearchParams(window.location.search)
          const email = params.get("email")
          if (email) {
            const sessions = store.dispatch(
              sessionsApi.endpoints.getSessions.initiate({
                email,
              })
            )
            return defer({
              sessions: sessions.unwrap(),
            })
          }
          return defer({
            sessions: await Promise.resolve(),
          })
        },
      },
      {
        path: "/incoming-enquiries",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <IncomingEnquiries />
          </Suspense>
        ),
        loader: () => {
          import("./screens/IncomingEnquiries/index")
          const params = new URLSearchParams(window.location.search)
          const inquiries = store.dispatch(
            incomingInquiriesApi.endpoints.getIncomingInquiries.initiate({
              page: Number(params.get("pageIndex")) + 1,
              limit: Number(params.get("pageSize")) || 10,
              search: params.get("searchText") || "",
              mine: params.get("mine") === "true",
            })
          )
          return defer({
            inquiries: inquiries.unwrap(),
          })
        },
      },
      {
        path: "/id-verification-status",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <IDVerificationStatus />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/IDVerificationStatus/index")
          const params = new URLSearchParams(window.location.search)
          const search_query = params.get("searchText")
          const limit = Number(params.get("pageSize"))
          const page = Number(params.get("pageIndex")) + 1
          if (search_query && limit && page) {
            const idVerificationStatus = store.dispatch(
              customersApi.endpoints.getVerificationStatus.initiate({
                search_query,
                limit,
                page,
              })
            )
            return defer({
              idVerificationStatus: idVerificationStatus.unwrap(),
            })
          }
          return defer({
            idVerificationStatus: await Promise.resolve(),
          })
        },
      },
      {
        path: "/seats-io-charts",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <SeatsIoCharts />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/SeatsIoCharts/index")
          const configuration = store.dispatch(
            seatsioApi.endpoints.getEventsConfiguration.initiate()
          )
          return defer({
            configuration: configuration.unwrap()
          })
        },
      },
      {
        path: "/upgrade-template",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <UpgradeTemplate />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/UpgradeTemplate/index")
          const templates = store.dispatch(
            templatesApi.endpoints.getTemplates.initiate()
          )
          return defer({
            templates: templates.unwrap(),
          })
        },
      },
      {
        path: "/permissions",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <Permissions />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/Permissions/index")
          const params = new URLSearchParams(window.location.search)
          if (params.get("email")) {
            const user = store.dispatch(
              customersApi.endpoints.getUserPermissions.initiate({
                email: params.get("email") || "",
              })
            )
            return defer({
              user: user.unwrap(),
            })
          }
          return defer({
            user: await Promise.resolve(),
          })
        },
      },
      {
        path: "/bypass-id-verification",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <IdVerificationManagement />
          </Suspense>
        ),
        loader: () => {
          import("./screens/IdVerificationManagement/index")
          const params = new URLSearchParams(window.location.search)
          const customers = store.dispatch(
            customersApi.endpoints.getVerificationManagement.initiate({
              page: Number(params.get("pageIndex")) + 1,
              limit: Number(params.get("pageSize")) || 10,
              search: params.get("searchText") || "",
            })
          )
          return defer({
            customers: customers.unwrap(),
          })
        },
      },
      {
        path: "/create-account",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <CreateAccount />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/CreateAccount/index")
          return {}
        },
      },
      {
        path: "/seats-io-events",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <SeatsIoEvents />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/SeatsIoEvents/index")
          return {}
        },
      },
      {
        path: "/seats-io-workspaces",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <SeatsIoWorkspaces />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/SeatsIoWorkspaces/index")
          return {}
        },
      },
      {
        path: "/user-management",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <UserManagement />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/UserManagement/index")
          const params = new URLSearchParams(window.location.search)
          const email = params.get("email")
          if (email) {
            const user = store.dispatch(
              customersApi.endpoints.getUser.initiate({
                email,
              })
            )
            return defer({
              user: user.unwrap(),
            })
          }
          return defer({
            user: await Promise.resolve(),
          })
        },
      },
      {
        path: "/venues",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <Venues />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/Venues/index")
          const params = new URLSearchParams(window.location.search)
          const venues = store.dispatch(
            venuesApi.endpoints.getVenues.initiate({
              search_query: params.get("searchText") || "",
              limit: Number(params.get("pageSize")) || 10,
              page: Number(params.get("pageIndex")) + 1,
            })
          )
          return defer({
            venues: venues.unwrap(),
          })
        },
      },
      {
        path: "/brand-verification",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <BrandVerification />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/BrandVerification/index")
          const params = new URLSearchParams(window.location.search)
          if (params.get("brandId")) {
            const brand = store.dispatch(
              brandsApi.endpoints.getBrandVerification.initiate({
                brandId: params.get("brandId") || "",
              })
            )
            return defer({
              brand: brand.unwrap(),
            })
          }
          return defer({
            brand: await Promise.resolve(),
          })
        },
      },
      {
        path: "/promoter-discount-links",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <LinkPromoCodeToPromoter />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/LinkPromoCodeToPromoter/index")
          const params = new URLSearchParams(window.location.search)
          const promoterDiscountLinks = store.dispatch(
            promoterDiscountLinksApi.endpoints.getPromoterDiscountLinks.initiate(
              {
                search_query: params.get("searchText") || "",
                limit: Number(params.get("pageSize")) || 10,
                page: Number(params.get("pageIndex")) + 1,
              }
            )
          )
          const events = store.dispatch(
            internalEventsApi.endpoints.getAllEvents.initiate()
          )
          return defer({
            promoterDiscountLinks: Promise.all([promoterDiscountLinks, events]),
          })
        },
      },
      {
        path: "/client-referrals",
        element: (
          <ClientReferrals />
        ),
        loader: async () => {
          const page = import("./screens/ClientReferrals/ClientReferrals")
          const clientReferrals = store.dispatch(
            referralsApi.endpoints.getClientReferrals.initiate(),
          ).unwrap()
          return defer({
            data: Promise.all([page, clientReferrals]),
          })
        },
      },
      {
        path: "/activity-logs",
        element: (
          <ActivityLogs />
        ),
        loader: async () => {
          const page = import("./screens/ActivityLogs/ActivityLogsPage")
          const params = new URLSearchParams(window.location.search)
          const customer = params.get('customer') || ""
          const type = params.get('type') || ""
          const start = params.get('start') || ""
          const end = params.get('end') || ""
          const activityLogs = store.dispatch(
            systemEventsApi.endpoints.getActivityLogs.initiate({
              customer,
              type,
              start: start || dayjs().format("YYYY-MM-DD"),
              end: end || dayjs().format("YYYY-MM-DD"),
              limit: Number(params.get('pageSize')) || 10,
              page: Number(params.get('pageIndex')) + 1,
            })
          )
          const eventTypes = store.dispatch(
            systemEventsApi.endpoints.getAutocompleteCustomerOrType.initiate({
              type: "type",
              text: '',
            })
          )
          return defer({
            data: Promise.all([page, activityLogs.unwrap(), eventTypes.unwrap()]),
          })
        },
      },
      {
        path: "/refund-orders",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <RefundOrders />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/RefundOrders/index")
          return {}
        }
      },
      {
        path: "/referral-refunds",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <ReferralRefunds />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/ReferralRefunds/index")
          const params = new URLSearchParams(window.location.search)
          const eventId = params.get('eventId')
          if (eventId) {
            const event = store.dispatch(
              internalEventsConsumerApi.endpoints.getReferralRefunds.initiate({
                eventId,
              })
            )
            const events = store.dispatch(
              internalEventsApi.endpoints.getAllEvents.initiate()
            )
            const trackedPromises = Promise.all([event.unwrap(), events.unwrap()])
            return defer({
              trackedPromises
            })
          }
          const events = store.dispatch(
            internalEventsApi.endpoints.getAllEvents.initiate()
          )
          return defer({
            trackedPromises: events.unwrap(),
          })
        },
      },
    ],
  },
])
