import { FC } from "react"
import { NavLink, useLocation } from "react-router-dom"

import {
  baseSpanLink,
  customSideBarLink,
  customSVGLink,
} from "./SideBarLink.css"

export const SideBarLink: FC<{
  to: string
  showSideBar: boolean
  setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
}> = ({ to, setShowSideBar, showSideBar, title, icon }) => {
  const { pathname } = useLocation()
  const isActive = pathname === to
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        showSideBar
          ? isActive
            ? customSideBarLink.openAndActive
            : customSideBarLink.openAndInactive
          : isActive
            ? customSideBarLink.closedAndActive
            : customSideBarLink.closedAndInactive
      }
      onClick={() => {
        if (pathname === to) {
          setShowSideBar(!showSideBar)
        }
      }}
    >
      {icon({
        className: showSideBar
          ? isActive
            ? customSVGLink.openAndActive
            : customSVGLink.openAndInactive
          : isActive
            ? customSVGLink.closedAndActive
            : customSVGLink.closedAndInactive,
      })}
      {showSideBar ? <span className={baseSpanLink}>{title}</span> : null}
    </NavLink>
  )
}
