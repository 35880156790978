import { FC, Suspense } from "react"
import { Await, Outlet, useLoaderData } from "react-router"

import { Header } from "../../components/Header"
import { DataLoaderHeader } from "../../types/types"
import { baseHomeContainer } from "../Home/Home.css"
import { ErrorHeaderMenu } from "./ErrorHeaderMenu"
import {
  baseHeaderContainer,
} from "./Header.css"
import { HeaderMenu } from "./HeaderMenu"
import { SiderMenu } from "./SiderMenu"

export const HeaderAuth: FC = () => {
  const { auth } = useLoaderData() as DataLoaderHeader
  return (
    <div className={baseHeaderContainer}>
      <Suspense fallback={<Header>{null}</Header>}>
        <Await
          resolve={auth}
          errorElement={
            <ErrorHeaderMenu />
          }
        >
          {() => (
            <HeaderMenu />
          )}
        </Await>
      </Suspense>
      <Suspense
        fallback={
          <div className={baseHomeContainer}>
            <Outlet />
          </div>
        }
      >
        <Await
          resolve={auth}
          errorElement={
            <div className={baseHomeContainer}>
              <Outlet />
            </div>
          }
        >
          {(auth) => (
            <SiderMenu auth={auth} />
          )}
        </Await >
      </Suspense >
    </div >
  )
}
