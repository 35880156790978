import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import {
    DisputesResponse,
} from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"

export const disputesApi = createApi({
    reducerPath: "disputessApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
        credentials: "include",
        headers: {
            "Accept": "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
            'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
        },
        prepareHeaders: async (headers) => {
            headers.set('X-TF-ECOMMERCE', getCookieByName('X-TF-ECOMMERCE'))
            return headers
        }
    }),
    tagTypes: ["Disputes"],
    endpoints: (build) => ({
        getDisputes: build.query<
            DisputesResponse,
            { search_query: string; page: number; limit: number }
        >({
            query: ({ search_query, page, limit }) => ({
                url:
                    "/disputes?" +
                    new URLSearchParams({
                        search_query,
                        page: String(page),
                        limit: String(limit),
                    }),
            }),
            providesTags: () => [{ type: "Disputes" }],
        }),
    }),
})

export const {
    useGetDisputesQuery,
} = disputesApi
