import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"

import {
    AllChartsResponse,
    AllWorkspacesResponse,
    ArchivedChartsResponse,
    BrandSeatProviderResponse,
    ChartCategoriesResponse,
    ChartReportResponse,
    GetAllTagsResponse,
    GetTicketsResponse,
    IndividualChartResponse,
    OneWorkspaceResponse,
    SeatsIoEventConfigurationResponse,
    SeatsIoEventDetailsResponse,
    SeatsIoEventsConfigurationResponse,
    SeatsIoResponse,
    UsageReportResponse,
    WorskpaceReportResponse
} from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"
import { processErrorResponse } from "./auth"

const baseQuery = fetchBaseQuery({
    baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
    credentials: "include",
    headers: {
        "Accept": "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
    },
    prepareHeaders: async (headers) => {
        headers.set('X-TF-ECOMMERCE', getCookieByName('X-TF-ECOMMERCE'))
        return headers
    }
})
const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)
    if (result.error && typeof args !== "string" && api.endpoint === "getEventConfiguration") {
        return {
            meta: result.meta,
            data: {
                data: null
            }
        }
    }
    if (result.error && typeof args !== "string" && api.endpoint === "getEventDetails") {
        return {
            meta: result.meta,
            data: {
                data: null
            }
        }
    }
    return result
}

export const seatsioApi = createApi({
    reducerPath: "seatsioApi",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["SeatsIoMaps", "SeatsIoMapsByVenue", "Configuration"],
    endpoints: (build) => ({
        getMapsByVenue: build.query<
            SeatsIoResponse,
            {
                venueId: string
            }
        >({
            query: ({ venueId }) => ({
                url: `/venue/${venueId}/seats-io/charts`
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when searching maps!", response),
            providesTags: ["SeatsIoMapsByVenue"]
        }),
        loadMoreVenue: build.query<
            SeatsIoResponse,
            {
                start_after_id: string
                venueId: string
            }
        >({
            query: ({ start_after_id, venueId }) => ({
                method: 'GET',
                url: `/venue/${venueId}/seats-io/charts?start_after_id=${start_after_id}`,
            }),
            async onQueryStarted({ venueId }, { dispatch, queryFulfilled }) {
                try {
                    const response = await queryFulfilled
                    dispatch(
                        seatsioApi.util.updateQueryData(
                            "getMapsByVenue",
                            { venueId },
                            (draft) => {
                                draft.data.attributes = [
                                    ...draft.data.attributes,
                                    ...response.data.data.attributes,
                                ]
                            }
                        )
                    )
                } catch { }
            },
        }),
        createEvent: build.mutation<
            void,
            {
                chartName: string
                eventId: string
                chartKey: string
            }
        >({
            query: ({ chartName, eventId, chartKey }) => ({
                method: 'POST',
                url: `/event/${eventId}/seats-io/events`,
                body: {
                    settings: {
                        chartKey,
                        chartName,
                    }
                }
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when updating workspace!", response)
        }),
        getEventDetails: build.query<
            SeatsIoEventDetailsResponse,
            {
                eventId: string
            }
        >({
            query: ({ eventId }) => ({
                method: 'GET',
                url: `/event/${eventId}/seats-io/events/main`,
            }),
            providesTags: ["SeatsIoMaps"],
            transformErrorResponse: (response) => processErrorResponse("Error when searching event!", response),
        }),
        getEventConfiguration: build.query<
            SeatsIoEventConfigurationResponse,
            {
                eventId: string
            }
        >({
            query: ({ eventId }) => ({
                method: 'GET',
                url: `/event/${eventId}/seats-io/events/main/configuration`,
            }),
            providesTags: ["Configuration"],
            transformErrorResponse: (response) => processErrorResponse("Error when searching event!", response),
        }),
        getEventsConfiguration: build.query<
            SeatsIoEventsConfigurationResponse,
            void
        >({
            query: () => ({
                method: 'GET',
                url: `/event/seats-io/events/configuration`,
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when fetching configuration!", response),
        }),
        linkTicket: build.mutation<
            void,
            {
                eventId: string
                ticketTypeId: string
                categoryKey: string
            }
        >({
            query: ({ eventId, ticketTypeId, categoryKey }) => ({
                method: 'POST',
                url: `/event/${eventId}/seats-io/events/main/link_ticket_type`,
                body: {
                    ticketTypeId,
                    categoryKey,
                }
            }),
            invalidatesTags: ["Configuration"],
            transformErrorResponse: (response) => processErrorResponse("Error when linking ticket!", response),
        }),
        unlinkTicketType: build.mutation<
            void,
            {
                eventId: string
                ticketTypeId: string
                categoryKey: string
            }
        >({
            query: ({ eventId, ticketTypeId, categoryKey }) => ({
                method: 'DELETE',
                url: `/event/${eventId}/seats-io/events/main/unlink_ticket_type`,
                body: {
                    ticketTypeId,
                    categoryKey,
                }
            }),
            invalidatesTags: ["Configuration"],
            transformErrorResponse: (response) => processErrorResponse("Error when unlinking ticket!", response),
        }),
        removeMapFromEvent: build.mutation<
            void,
            {
                eventId: string
            }
        >({
            query: ({ eventId }) => ({
                method: 'DELETE',
                url: `/event/${eventId}/seats-io/events/main`,
            }),
            invalidatesTags: ["Configuration", "SeatsIoMaps"],
            transformErrorResponse: (response) => processErrorResponse("Error when removing map!", response),
        }),
    }),
})

const baseQueryConsumer = fetchBaseQuery({
    baseUrl: `${ENV.API_CONSUMER}/api`,
    credentials: "include",
    headers: {
        "Accept": "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
    },
    prepareHeaders: async (headers) => {
        headers.set('X-TF-ECOMMERCE', getCookieByName('X-TF-ECOMMERCE'))
        return headers
    }
})
const baseQueryWithReauthConsumer: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQueryConsumer(args, api, extraOptions)
    if (result.error && typeof args !== "string" && api.endpoint === "getSessions") {
        return {
            meta: result.meta,
            data: {
                data: {
                    user: {
                        email: "",
                        user_id: "",
                        sessions: []
                    },
                }
            }
        }
    }
    return result
}

export const seatsioConsumerApi = createApi({
    reducerPath: "seatsioConsumerApi",
    baseQuery: baseQueryWithReauthConsumer,
    tagTypes: [
        "SeatsIoMapsConsumer",
        "ActiveCharts",
        "ArchivedCharts",
        "Categories",
        "OneChart",
        "OneWorkspace",
        "AllWorkspaces",
        "BrandSeatProvider",
    ],
    endpoints: (build) => ({
        allTags: build.query<
            GetAllTagsResponse,
            void
        >({
            query: () => ({
                method: 'GET',
                url: `/v1/seats-io/charts/tags`
            }),
        }),
        report: build.query<
            ChartReportResponse,
            {
                chartKey: string
                type: 'bySection' | 'byLabel' | 'byCategoryKey' | 'byCategoryLabel' | 'byObjectType'
            }
        >({
            query: ({ chartKey, type }) => ({
                method: 'GET',
                url: `/v1/seats-io/charts/${chartKey}/reports/${type}`
            }),
        }),
        generalReport: build.query<
            UsageReportResponse,
            { type: string; month: string }
        >({
            query: ({ type, month }) => ({
                method: 'GET',
                url: `/v1/seats-io/reports/usage?` + new URLSearchParams({
                    type,
                    month,
                })
            }),
        }),
        addTag: build.mutation<
            void,
            {
                chartKey: string
                tag: string
                venueId?: string
            }
        >({
            query: ({ chartKey, tag }) => ({
                method: 'POST',
                url: `/v1/seats-io/charts/${chartKey}/tags/${tag}`
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when adding tags!", response),
            async onQueryStarted({ tag, chartKey, venueId }, { dispatch, queryFulfilled }) {
                const patchResultActive = dispatch(
                    seatsioConsumerApi.util.updateQueryData(
                        "getAllCharts",
                        undefined,
                        (draft) => {
                            const index = draft.data.attributes.findIndex(
                                (chart) => chart.key === chartKey
                            )
                            if (index !== -1) {
                                draft.data.attributes[index].tags.push(tag)
                            }
                        }
                    )
                )
                const patchResultInactive = dispatch(
                    seatsioConsumerApi.util.updateQueryData(
                        "getArchivedCharts",
                        undefined,
                        (draft) => {
                            const index = draft.data.attributes.items.findIndex(
                                (chart) => chart.key === chartKey
                            )
                            if (index !== -1) {
                                draft.data.attributes.items[index].tags.push(tag)
                            }
                        }
                    )
                )
                const patchResultByVenue = venueId ? dispatch(
                    seatsioApi.util.updateQueryData(
                        "getMapsByVenue",
                        { venueId },
                        (draft) => {
                            const index = draft.data.attributes.findIndex(
                                (chart) => chart.chartKey === chartKey
                            )
                            if (index !== -1) {
                                draft.data.attributes[index].tags.push(tag)
                            }
                        }
                    )
                ) : null
                try {
                    await queryFulfilled
                } catch {
                    patchResultActive.undo()
                    patchResultInactive.undo()
                    patchResultByVenue && patchResultByVenue.undo()
                }
            },
        }),
        deleteTag: build.mutation<
            void,
            {
                chartKey: string
                tag: string
                venueId: string
            }
        >({
            query: ({ chartKey, tag }) => ({
                method: 'DELETE',
                url: `/v1/seats-io/charts/${chartKey}/tags/${tag}`
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when deleting tags!", response),
            async onQueryStarted({ tag, chartKey, venueId }, { dispatch, queryFulfilled }) {
                const patchResultActive = dispatch(
                    seatsioConsumerApi.util.updateQueryData(
                        "getAllCharts",
                        undefined,
                        (draft) => {
                            const index = draft.data.attributes.findIndex(
                                (chart) => chart.key === chartKey
                            )
                            if (index !== -1) {
                                draft.data.attributes[index].tags =
                                    draft.data.attributes[index].tags.filter(eventTag => eventTag !== tag)
                            }
                        }
                    )
                )
                const patchResultInactive = dispatch(
                    seatsioConsumerApi.util.updateQueryData(
                        "getArchivedCharts",
                        undefined,
                        (draft) => {
                            const index = draft.data.attributes.items.findIndex(
                                (chart) => chart.key === chartKey
                            )
                            if (index !== -1) {
                                draft.data.attributes.items[index].tags =
                                    draft.data.attributes.items[index].tags.filter(eventTag => eventTag !== tag)
                            }
                        }
                    )
                )
                const patchResultByVenue = venueId ? dispatch(
                    seatsioApi.util.updateQueryData(
                        "getMapsByVenue",
                        { venueId },
                        (draft) => {
                            const index = draft.data.attributes.findIndex(
                                (chart) => chart.chartKey === chartKey
                            )
                            if (index !== -1) {
                                draft.data.attributes[index].tags =
                                    draft.data.attributes[index].tags.filter(eventTag => eventTag !== tag)
                            }
                        }
                    )
                ) : null
                try {
                    await queryFulfilled
                } catch {
                    patchResultActive.undo()
                    patchResultInactive.undo()
                    patchResultByVenue && patchResultByVenue.undo()
                }
            },
        }),
        allCategories: build.query<
            ChartCategoriesResponse,
            { chartKey: string }
        >({
            query: ({ chartKey }) => ({
                method: 'GET',
                url: `/v1/seats-io/charts/${chartKey}/categories`
            }),
            providesTags: (_res, _error, { chartKey }) => [{ type: "Categories", chartKey }],
        }),
        addCategory: build.mutation<
            void,
            {
                chartKey: string
                key: string
                label: string
                color: string
            }
        >({
            query: ({ chartKey, key, label, color }) => ({
                method: 'POST',
                url: `/v1/seats-io/charts/${chartKey}/categories`,
                body: {
                    data: {
                        attributes: {
                            key,
                            label,
                            color,
                        }
                    }
                }
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when adding category!", response),
        }),
        deleteCategory: build.mutation<
            void,
            {
                chartKey: string
                category: string
            }
        >({
            query: ({ chartKey, category }) => ({
                method: 'DELETE',
                url: `/v1/seats-io/charts/${chartKey}/categories/${category}`
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when deleting category!", response),
        }),
        publishChart: build.mutation<
            void,
            {
                chartKey: string
            }
        >({
            query: ({ chartKey }) => ({
                method: 'POST',
                url: `/v1/seats-io/charts/${chartKey}/publish`
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when publishing chart!", response)
        }),
        discardChartDraft: build.mutation<
            void,
            {
                chartKey: string
            }
        >({
            query: ({ chartKey }) => ({
                method: 'POST',
                url: `/v1/seats-io/charts/${chartKey}/discard`
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when discarting chart draft!", response)
        }),
        moveToArchive: build.mutation<
            void,
            {
                chartKey: string
            }
        >({
            query: ({ chartKey }) => ({
                method: 'POST',
                url: `/v1/seats-io/charts/${chartKey}/archive`
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when archiving chart!", response),
            invalidatesTags: ["ActiveCharts", "ArchivedCharts", "OneChart"]
        }),
        copyChart: build.mutation<
            void,
            {
                chartKey: string
            }
        >({
            query: ({ chartKey }) => ({
                method: 'POST',
                url: `/v1/seats-io/charts/${chartKey}/copy`
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when copying chart!", response),
            invalidatesTags: ["ActiveCharts", "ArchivedCharts"]
        }),
        getArchivedCharts: build.query<
            ArchivedChartsResponse,
            void
        >({
            query: () => ({
                method: 'GET',
                url: `/v1/seats-io/charts/archive`
            }),
            providesTags: ["ArchivedCharts"]
        }),
        moveToActive: build.mutation<
            void,
            {
                chartKey: string
            }
        >({
            query: ({ chartKey }) => ({
                method: 'POST',
                url: `/v1/seats-io/charts/${chartKey}/activate`
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when activating chart!", response),
            invalidatesTags: ["ActiveCharts", "ArchivedCharts", "OneChart"]
        }),
        createChart: build.mutation<
            void,
            {
                name: string
                venueType: string
            }
        >({
            query: ({ name, venueType }) => ({
                method: 'POST',
                url: `/v1/seats-io/charts`,
                body: {
                    data: {
                        attributes: {
                            name,
                            venueType,
                        }
                    }
                }
            }),
            invalidatesTags: ["ActiveCharts", "ArchivedCharts"],
            transformErrorResponse: (response) => processErrorResponse("Error when creating chart!", response)
        }),
        getChart: build.query<
            IndividualChartResponse,
            {
                chartKey: string
            }
        >({
            query: ({ chartKey }) => ({
                method: 'GET',
                url: `/v1/seats-io/charts/${chartKey}`,
            }),
            providesTags: ["OneChart"]
        }),
        getAllCharts: build.query<
            AllChartsResponse,
            void
        >({
            query: () => ({
                method: 'GET',
                url: `/v1/seats-io/charts`,
            }),
            providesTags: ["ActiveCharts"]
        }),
        getAllWorkspaces: build.query<
            AllWorkspacesResponse,
            void
        >({
            query: () => ({
                method: 'GET',
                url: `/v1/seats-io/workspaces`,
            }),
            providesTags: ['AllWorkspaces']
        }),
        getOneWorkspace: build.query<
            OneWorkspaceResponse,
            {
                workspaceKey: string
            }
        >({
            query: ({ workspaceKey }) => ({
                method: 'GET',
                url: `/v1/seats-io/workspaces/${workspaceKey}`,
            }),
            providesTags: ['OneWorkspace']
        }),
        createWorkspace: build.mutation<
            void,
            {
                name: string
                isTest: boolean
            }
        >({
            query: ({ name, isTest }) => ({
                method: 'POST',
                url: `/v1/seats-io/workspaces`,
                body: {
                    data: {
                        attributes: {
                            name,
                            isTest,
                        }
                    }
                }
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when creating workspace!", response),
            invalidatesTags: ['AllWorkspaces', 'OneWorkspace']
        }),
        updateWorkspace: build.mutation<
            void,
            {
                name: string
                workspaceKey: string
            }
        >({
            query: ({ name, workspaceKey }) => ({
                method: 'PUT',
                url: `/v1/seats-io/workspaces/${workspaceKey}`,
                body: {
                    data: {
                        attributes: {
                            name,
                        }
                    }
                }
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when updating workspace!", response),
            async onQueryStarted({ workspaceKey, name }, { dispatch, queryFulfilled }) {
                const patchResultAll = dispatch(
                    seatsioConsumerApi.util.updateQueryData(
                        "getAllWorkspaces",
                        undefined,
                        (draft) => {
                            const index = draft.data.attributes.findIndex(
                                (workspace) => workspace.key === workspaceKey
                            )
                            if (index !== -1) {
                                draft.data.attributes[index].name = name
                            }
                        }
                    )
                )
                const patchResultOne = dispatch(
                    seatsioConsumerApi.util.updateQueryData(
                        "getOneWorkspace",
                        { workspaceKey },
                        (draft) => {
                            draft.data.attributes.name = name
                        }
                    )
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResultAll.undo()
                    patchResultOne.undo()
                }
            },
        }),
        regenerateSecretKey: build.mutation<
            void,
            {
                key: string
            }
        >({
            query: ({ key }) => ({
                method: 'POST',
                url: `/v1/seats-io/workspaces/${key}/regenerate-key`,
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when regenerating secret key!", response),
            invalidatesTags: ['AllWorkspaces', 'OneWorkspace']
        }),
        updateWorkspaceStatus: build.mutation<
            void,
            {
                status: 'activate' | 'deactivate'
                id: string
            }
        >({
            query: ({ status, id }) => ({
                method: 'POST',
                url: `/v1/seats-io/workspaces/${id}/set-status`,
                body: {
                    data: {
                        attributes: {
                            status,
                        }
                    }
                }
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when updating workspace!", response)
        }),
        getBrandSeatProvider: build.query<
            BrandSeatProviderResponse,
            {
                brandId: string
            }
        >({
            query: ({ brandId }) => ({
                method: 'GET',
                url: `/v1/brand/${brandId}/seat-provider`,
            }),
        }),
        setBrandSeatProvider: build.mutation<
            void,
            {
                seatProvider: string
                brandId: string
            }
        >({
            query: ({ seatProvider, brandId }) => ({
                method: 'POST',
                url: `/v1/brand/${brandId}/seat-provider`,
                body: {
                    seatProvider,
                }
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when updating brand!", response)
        }),
        getEventSeatProvider: build.query<
            BrandSeatProviderResponse,
            {
                eventId: string
            }
        >({
            query: ({ eventId }) => ({
                method: 'GET',
                url: `/v1/event/${eventId}/seat-provider`,
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when searching event!", response),
        }),
        setEventSeatProvider: build.mutation<
            void,
            {
                seatProvider: string
                eventId: string
            }
        >({
            query: ({ eventId, seatProvider }) => ({
                method: 'POST',
                url: `/v1/event/${eventId}/seat-provider`,
                body: {
                    seatProvider,
                }
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when updating event!", response)
        }),
        getReport: build.query<
            WorskpaceReportResponse,
            {
                month: string
                type: string
            }
        >({
            query: ({ month, type }) => ({
                method: 'GET',
                url: `/v1/seats-io/reports/usage/month/${month}?` + new URLSearchParams({
                    type,
                    month,
                }),
            }),
        }),
        getEventReport: build.query<
            WorskpaceReportResponse,
            {
                month: string
                eventId: string
                type: string
            }
        >({
            query: ({ month, eventId, type }) => ({
                method: 'GET',
                url: `/v1/seats-io/reports/usage/month/${month}/event/${eventId}?` + new URLSearchParams({
                    type,
                    month,
                }),
            }),
        }),
        getTickets: build.query<
            GetTicketsResponse,
            {
                eventId: string
            }
        >({
            query: ({ eventId }) => ({
                method: 'GET',
                url: `/v1/event/${eventId}/tickets`,
            }),
            transformErrorResponse: (response) => processErrorResponse("Error when searching event!", response),
        }),
        loadMoreAll: build.query<
            AllChartsResponse,
            {
                start_after_id: string
            }
        >({
            query: ({ start_after_id }) => ({
                method: 'GET',
                url: `/v1/seats-io/charts?start_after_id=${start_after_id}`,
            }),
            async onQueryStarted(_args, { dispatch, queryFulfilled }) {
                try {
                    const response = await queryFulfilled
                    dispatch(
                        seatsioConsumerApi.util.updateQueryData(
                            "getAllCharts",
                            undefined,
                            (draft) => {
                                draft.data.attributes = [...draft.data.attributes, ...response.data.data.attributes]
                            }
                        )
                    )
                } catch { }
            },
        }),
        loadMoreArchived: build.query<
            ArchivedChartsResponse,
            {
                start_after_id: string
            }
        >({
            query: ({ start_after_id }) => ({
                method: 'GET',
                url: `/v1/seats-io/charts/archive?start_after_id=${start_after_id}`,
            }),
            async onQueryStarted(_args, { dispatch, queryFulfilled }) {
                try {
                    const response = await queryFulfilled
                    dispatch(
                        seatsioConsumerApi.util.updateQueryData(
                            "getArchivedCharts",
                            undefined,
                            (draft) => {
                                draft.data.attributes.items = [
                                    ...draft.data.attributes.items,
                                    ...response.data.data.attributes.items,
                                ]
                            }
                        )
                    )
                } catch { }
            },
        }),
    }),
})

export const {
    useLazyGetMapsByVenueQuery,
    useCreateEventMutation,
    useLazyGetEventDetailsQuery,
    useLazyGetEventConfigurationQuery,
    useLinkTicketMutation,
    useUnlinkTicketTypeMutation,
    useLazyLoadMoreVenueQuery,
    useGetEventsConfigurationQuery,
    useRemoveMapFromEventMutation,
} = seatsioApi

export const {
    useAddTagMutation,
    useDeleteTagMutation,
    useLazyReportQuery,
    useLazyGeneralReportQuery,
    useLazyAllTagsQuery,
    useDeleteCategoryMutation,
    useAddCategoryMutation,
    useLazyAllCategoriesQuery,
    useDiscardChartDraftMutation,
    usePublishChartMutation,
    useMoveToArchiveMutation,
    useCopyChartMutation,
    useLazyGetArchivedChartsQuery,
    useMoveToActiveMutation,
    useCreateChartMutation,
    useLazyGetAllChartsQuery,
    useLazyGetChartQuery,
    useLazyGetAllWorkspacesQuery,
    useLazyGetOneWorkspaceQuery,
    useCreateWorkspaceMutation,
    useUpdateWorkspaceStatusMutation,
    useUpdateWorkspaceMutation,
    useRegenerateSecretKeyMutation,
    useLazyGetBrandSeatProviderQuery,
    useSetBrandSeatProviderMutation,
    useLazyGetEventSeatProviderQuery,
    useSetEventSeatProviderMutation,
    useLazyGetReportQuery,
    useLazyGetEventReportQuery,
    useLazyGetTicketsQuery,
    useLazyLoadMoreAllQuery,
    useLazyLoadMoreArchivedQuery,
} = seatsioConsumerApi