import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import {
    ResetVerificationRequest, UpgradeTemplatesResponse,
} from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"
import { processErrorResponse } from "./auth"

export const templatesApi = createApi({
    reducerPath: "templatesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
        credentials: "include",
        headers: {
            "Accept": "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
            'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
        },
        prepareHeaders: async (headers) => {
            headers.set('X-TF-ECOMMERCE', getCookieByName('X-TF-ECOMMERCE'))
            return headers
        }
    }),
    tagTypes: ["Templates"],
    endpoints: (build) => ({
        getTemplates: build.query<
            UpgradeTemplatesResponse,
            void
        >({
            query: () => ({
                url: "/upgrade-templates"
            }),
            providesTags: () => [{ type: "Templates" }],
        }),
        createTemplate: build.mutation<
            ResetVerificationRequest,
            { slug: string; template: string }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when adding template!", response),
            query: ({ template, slug }) => ({
                method: "POST",
                url: `/upgrade-templates`,
                body: {
                    data: {
                        attributes: {
                            template,
                            slug
                        }
                    }
                }
            }),
            invalidatesTags: ['Templates'],
        }),
        editTemplate: build.mutation<
            ResetVerificationRequest,
            { template_id: string; slug: string; template: string }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when editing template!", response),
            query: ({ template_id, slug, template }) => ({
                method: "PATCH",
                url: `/upgrade-templates/${template_id}`,
                body: {
                    data: {
                        attributes: {
                            slug,
                            template,
                        }
                    }
                }
            }),
            async onQueryStarted(
                {
                    slug,
                    template,
                    template_id,
                },
                { dispatch, queryFulfilled }
            ) {
                const change = dispatch(
                    templatesApi.util.updateQueryData(
                        "getTemplates",
                        undefined,
                        (draft) => {
                            const index = draft.data.templates.findIndex(
                                (template) => template.id === template_id
                            )
                            if (index !== -1) {
                                draft.data.templates[index].campaign_slug = slug
                                draft.data.templates[index].template = template
                            }
                        }
                    )
                )
                try {
                    await queryFulfilled
                } catch {
                    change.undo()
                }
            },
        }),
        deleteTemplate: build.mutation<
            ResetVerificationRequest,
            { template_id: string }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when deleting template!", response),
            query: ({ template_id }) => ({
                method: "DELETE",
                url:
                    `/upgrade-templates/${template_id}`,
            }),
            async onQueryStarted(
                {
                    template_id,
                },
                { dispatch, queryFulfilled }
            ) {
                const change = dispatch(
                    templatesApi.util.updateQueryData(
                        "getTemplates",
                        undefined,
                        (draft) => {
                            const index = draft.data.templates.findIndex(
                                (template) => template.id === template_id
                            )
                            if (index !== -1) {
                                draft.data.templates.splice(index, 1)
                            }
                        }
                    )
                )
                try {
                    await queryFulfilled
                } catch {
                    change.undo()
                }
            },
        })
    }),
})

export const {
    useGetTemplatesQuery,
    useCreateTemplateMutation,
    useEditTemplateMutation,
    useDeleteTemplateMutation
} = templatesApi