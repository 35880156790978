import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { VenuesResponse } from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"
import { processErrorResponse } from "./auth"

export const venuesApi = createApi({
    reducerPath: "venuesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
        credentials: "include",
        headers: {
            "Accept": "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
            'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
        },
        prepareHeaders: async (headers) => {
            headers.set('X-TF-ECOMMERCE', getCookieByName('X-TF-ECOMMERCE'))
            return headers
        }
    }),
    tagTypes: ["Venues"],
    endpoints: (build) => ({
        getVenues: build.query<
            VenuesResponse,
            { search_query: string; page: number; limit: number }
        >({
            query: ({ search_query, page, limit }) => ({
                url:
                    "/venues-list?" +
                    new URLSearchParams({
                        search_query,
                        page: String(page),
                        limit: String(limit),
                    }),
            }),
            providesTags: () => [{ type: "Venues" }],
        }),
        editVenue: build.mutation<
            void,
            {
                id: string
                venue_name: string
                country_id: string
                country_name: string
                city: string
                state: string
                street: string
                street_number: string | null
                postal_code: string
                email_address: string | null
                phone_number: string | null
                manager_contact_name: string | null
                manager_contact_address: string | null
                website: string | null
                hidden: boolean
                disabled_access: boolean
                search_query: string
                page: number
                limit: number
            }
        >({
            transformErrorResponse: (response) => processErrorResponse("Error when editing venue!", response),
            query: ({ id,
                venue_name,
                country_id,
                city,
                state,
                street,
                street_number,
                postal_code,
                email_address,
                phone_number,
                manager_contact_name,
                manager_contact_address,
                website,
                hidden,
                disabled_access,
            }) => ({
                url: `/venue/${id}`,
                method: "PATCH",
                body: {
                    data: {
                        attributes: {
                            venue_name,
                            country_id,
                            city,
                            state,
                            street,
                            street_number,
                            postal_code,
                            email_address,
                            phone_number,
                            manager_contact_name,
                            manager_contact_address,
                            website,
                            hidden,
                            disabled_access,
                        },
                    },
                },
            }),
            async onQueryStarted({
                search_query,
                page,
                limit,
                id,
                venue_name,
                country_name,
                country_id,
                city,
                state,
                street,
                street_number,
                postal_code,
                email_address,
                phone_number,
                manager_contact_name,
                manager_contact_address,
                website,
                hidden,
                disabled_access,
            }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    venuesApi.util.updateQueryData(
                        "getVenues",
                        { search_query, page, limit },
                        (draft) => {
                            const index = draft.data.venues.findIndex(
                                (venue) => venue.id === id
                            )
                            if (index !== -1) {
                                draft.data.venues[index].city = city
                                draft.data.venues[index].venue_name = venue_name
                                draft.data.venues[index].country_name = country_name
                                draft.data.venues[index].country_id = country_id
                                draft.data.venues[index].state = state
                                draft.data.venues[index].street = street
                                draft.data.venues[index].street_number = street_number
                                draft.data.venues[index].postal_code = postal_code
                                draft.data.venues[index].email_address = email_address
                                draft.data.venues[index].phone_number = phone_number
                                draft.data.venues[index].manager_contact_name = manager_contact_name
                                draft.data.venues[index].manager_contact_address = manager_contact_address
                                draft.data.venues[index].website = website
                                draft.data.venues[index].hidden = hidden
                                draft.data.venues[index].disabled_access = disabled_access
                            }
                        }
                    )
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
        }),
    }),
})

export const {
    useGetVenuesQuery,
    useEditVenueMutation,
} = venuesApi
