import { configureStore } from "@reduxjs/toolkit"

import { authApi, passwordRestoreApi } from "./auth"
import { brandsApi } from "./brands"
import { customersApi } from "./customers"
import { disputesApi } from "./disputes"
import { internalEventsApi, internalEventsConsumerApi } from "./events"
import { incomingInquiriesApi } from "./inquiries"
import { ordersApi } from "./orders"
import { promoterDiscountLinksApi } from "./promoterDiscountLinks"
import { referralsApi } from "./referrals"
import { seatsioApi, seatsioConsumerApi } from "./seatsio"
import { sessionsApi } from "./sessions"
import { systemEventsApi } from "./systemEvents"
import { templatesApi } from "./templates"
import { ticketsApi } from "./ticket"
import { venuesApi } from "./venues"

export const store = configureStore({
  reducer: {
    [internalEventsApi.reducerPath]: internalEventsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [passwordRestoreApi.reducerPath]: passwordRestoreApi.reducer,
    [brandsApi.reducerPath]: brandsApi.reducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [disputesApi.reducerPath]: disputesApi.reducer,
    [sessionsApi.reducerPath]: sessionsApi.reducer,
    [incomingInquiriesApi.reducerPath]: incomingInquiriesApi.reducer,
    [seatsioApi.reducerPath]: seatsioApi.reducer,
    [seatsioConsumerApi.reducerPath]: seatsioConsumerApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [venuesApi.reducerPath]: venuesApi.reducer,
    [internalEventsConsumerApi.reducerPath]: internalEventsConsumerApi.reducer,
    [promoterDiscountLinksApi.reducerPath]: promoterDiscountLinksApi.reducer,
    [referralsApi.reducerPath]: referralsApi.reducer,
    [systemEventsApi.reducerPath]: systemEventsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      passwordRestoreApi.middleware,
      internalEventsApi.middleware,
      brandsApi.middleware,
      ticketsApi.middleware,
      disputesApi.middleware,
      sessionsApi.middleware,
      incomingInquiriesApi.middleware,
      customersApi.middleware,
      seatsioApi.middleware,
      seatsioConsumerApi.middleware,
      templatesApi.middleware,
      venuesApi.middleware,
      internalEventsConsumerApi.middleware,
      promoterDiscountLinksApi.middleware,
      referralsApi.middleware,
      systemEventsApi.middleware,
      ordersApi.middleware,
    ]),
})
