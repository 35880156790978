import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react"

import { AuthResponse, TTFError, TTFErrorArray } from "../types/types"
import { ENV } from "../utils/env"
import { deleteCookieByName, getCookieByName, setCustomCookie } from "../utils/session"

export const processErrorResponse = (defaultMessage: string, response: FetchBaseQueryError) => {
  const data = response.data as TTFErrorArray | TTFError
  if ("errors" in data) {
    return data.errors[0].details
  }
  if ("error" in data) {
    return data.message
  }
  return defaultMessage
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${ENV.API_CONSUMER || "https://www.test.com"}/api`,
  credentials: "include",
  headers: {
    "Accept": "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
  },
  prepareHeaders: async (headers) => {
    headers.set('X-TF-ECOMMERCE', getCookieByName('X-TF-ECOMMERCE'))
    return headers
  }
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (typeof args !== "string" && api.endpoint === "getSession") {
    const { meta } = result
    const data = result?.data as AuthResponse
    const tfStaff = data?.data?.tfStaff
    const isSuperAdmin = data?.data?.isSuperAdmin
    if (!tfStaff && !isSuperAdmin) {
      const error: FetchBaseQueryError = {
        status: 401,
        data: {
          error: true,
          message: "Unauthorized",
          status: 401,
          success: false,
        }
      }
      return {
        meta,
        error,
      }
    }
  }
  return result
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Auth"],
  endpoints: (build) => ({
    getSession: build.query<AuthResponse, void>({
      query: () => ({
        url: "/auth?" +
          new URLSearchParams({
            newSignupFlow: 'true',
          }),
      }),
      providesTags: () => [{ type: "Auth" }],
      transformResponse: (response: AuthResponse, meta: FetchBaseQueryMeta): AuthResponse => {
        const xtfcommerce = meta?.response?.headers.get("X-TF-ECOMMERCE")
        if (xtfcommerce) {
          setCustomCookie('X-TF-ECOMMERCE', xtfcommerce)
        }
        return response
      },
      transformErrorResponse: (response) => {
        deleteCookieByName('X-TF-ECOMMERCE')
        return response
      },
    }),
    logIn: build.mutation<
      AuthResponse,
      {
        email: string
        password: string
      }
    >({
      transformResponse: (response: AuthResponse, meta: FetchBaseQueryMeta): AuthResponse => {
        const xtfcommerce = meta?.response?.headers.get("X-TF-ECOMMERCE")
        if (xtfcommerce) {
          setCustomCookie('X-TF-ECOMMERCE', xtfcommerce)
        }
        return response
      },
      transformErrorResponse: (response) => {
        deleteCookieByName('X-TF-ECOMMERCE')
        return processErrorResponse("Error when signing in!", response)
      },
      query: ({ email, password }) => ({
        url: `/auth`,
        method: "POST",
        body: {
          email,
          password,
          newSignupFlow: true,
        },
      }),
    }),
    logOut: build.mutation<unknown, void>({
      transformResponse: (response): unknown => {
        deleteCookieByName('X-TF-ECOMMERCE')
        return response
      },
      transformErrorResponse: (response) => {
        deleteCookieByName('X-TF-ECOMMERCE')
        return processErrorResponse("Error when signing out!", response)
      },
      query: () => ({
        url: `/auth`,
        method: "DELETE",
      }),
    }),
    logInAs: build.mutation<
      void,
      { customer_id: string }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when starting session!", response),
      query: ({ customer_id }) => ({
        method: "POST",
        url:
          `/log-in-as/${customer_id}`,
      }),
    }),
  }),
})

export const passwordRestoreApi = createApi({
  reducerPath: "passwordRestoreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.BUSINESS_CONSUMER || "https://www.test.com"}/api`,
    credentials: "include",
    headers: {
      "Accept": "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  }),
  tagTypes: ["PasswordRestore"],
  endpoints: (build) => ({
    passwordRestore: build.mutation<
      void,
      {
        email: string
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when restoring password!", response),
      query: ({ email }) => ({
        url: `/password-restore`,
        method: "POST",
        body: {
          data: {
            type: "password_reset",
            attributes: {
              email,
            },
          },
        },
      }),
    }),
  }),
})

export const {
  useLogInMutation,
  useLogOutMutation,
  useGetSessionQuery,
  useLogInAsMutation,
} = authApi

export const {
  usePasswordRestoreMutation
} = passwordRestoreApi
