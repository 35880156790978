import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"

import {
  CustomerResponse,
  GetUserResponse,
  IdVerificationManagementResponse,
  ResetVerificationRequest,
  UserPermissionsResponse,
} from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"
import { processErrorResponse } from "./auth"

const baseQuery = fetchBaseQuery({
  baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
  credentials: "include",
  headers: {
    "Accept": "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
  },
  prepareHeaders: async (headers) => {
    headers.set('X-TF-ECOMMERCE', getCookieByName('X-TF-ECOMMERCE'))
    return headers
  }
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error && typeof args !== "string" && api.endpoint === "getUserPermissions") {
    return {
      meta: result.meta,
      data: {}
    }
  }
  if (result.error && typeof args !== "string" && api.endpoint === "getUser") {
    return {
      meta: result.meta,
      data: {
        data: {
          user: null,
        }
      }
    }
  }
  return result
}

export const customersApi = createApi({
  reducerPath: "customersApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Customers", "Customer", "CustomersVerificationManagement", "CustomersPermissions"],
  endpoints: (build) => ({
    getUser: build.query<
      GetUserResponse,
      { email: string }
    >({
      query: ({ email }) => ({
        url:
          `/user/${email}`
      }),
      providesTags: () => [{ type: "Customer" }],
    }),
    getVerificationManagement: build.query<
      IdVerificationManagementResponse,
      { search: string; page: number; limit: number }
    >({
      query: ({ search, page, limit }) => ({
        url:
          "/id-verification-management?" +
          new URLSearchParams({
            search_query: search,
            page: String(page),
            limit: String(limit),
          }),
      }),
      providesTags: () => [{ type: "CustomersVerificationManagement" }],
    }),
    getVerificationStatus: build.query<
      CustomerResponse,
      { search_query: string; page: number; limit: number }
    >({
      query: ({ search_query, page, limit }) => ({
        url:
          "/id-verification-status?" +
          new URLSearchParams({
            search_query,
            page: String(page),
            limit: String(limit),
          }),
      }),
      providesTags: () => [{ type: "Customers" }],
    }),
    updateBypass: build.mutation<
      void,
      {
        search: string
        page: number
        limit: number
        customer_id: string
        value: boolean
      }
    >({
      transformErrorResponse: (response) =>
        processErrorResponse("Error when editing event!", response),
      query: ({ customer_id, value }) => ({
        method: "PATCH",
        url: `/id-verification-management/${customer_id}`,
        body: {
          data: {
            attributes: {
              value,
            },
          },
        },
      }),
      async onQueryStarted(
        { customer_id, search, page, limit, value },
        { dispatch, queryFulfilled }
      ) {
        const change = dispatch(
          customersApi.util.updateQueryData(
            "getVerificationManagement",
            { search, page, limit },
            (draft) => {
              const index = draft.data.customers.findIndex(
                (customer) => customer.id === customer_id
              )
              if (index !== -1) {
                draft.data.customers[index].bypass = value
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          change.undo()
        }
      },
    }),
    resetVerification: build.mutation<
      ResetVerificationRequest,
      { search_query: string; page: number; limit: number; customer_id: string }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when editing event!", response),
      query: ({ customer_id }) => ({
        method: "PATCH",
        url:
          `/id-verification-status/${customer_id}`,
      }),
      async onQueryStarted(
        {
          customer_id,
          search_query,
          page,
          limit,
        },
        { dispatch, queryFulfilled }
      ) {
        try {
          const response = await queryFulfilled
          dispatch(
            customersApi.util.updateQueryData(
              "getVerificationStatus",
              { search_query, page, limit },
              (draft) => {
                const index = draft.data.customers.findIndex(
                  (customer) => customer.id === customer_id
                )
                if (index !== -1) {
                  draft.data.customers[index] = response.data.data.customer
                }
              }
            )
          )
        } catch { }
      },
    }),
    getUserPermissions: build.query<
      UserPermissionsResponse,
      { email: string }
    >({
      query: ({ email }) => ({
        url: `/user-permissions/${email}`
      }),
      providesTags: (_result, _error, { email }) => [{ type: "CustomersPermissions", email }],
    }),
    editUserPermissions: build.mutation<
      ResetVerificationRequest,
      { id: string; email: string; entity_type: string; role: string; entity_type_id: string }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when editing permission!", response),
      query: ({ id, entity_type, role, entity_type_id }) => ({
        method: "PATCH",
        url: `/user-permissions/${id}`,
        body: {
          data: {
            attributes: {
              entity_type,
              role,
              entity_type_id
            }
          }
        }
      }),
      invalidatesTags: (_result, _error, { email }) => [{ type: 'CustomersPermissions', email }]
    }),
    editTTFUserPermissions: build.mutation<
      ResetVerificationRequest,
      { id: string; email: string; ttf_role: string; payout_admin: boolean; tf_media: boolean }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when editing permission!", response),
      query: ({ id, ttf_role, payout_admin, tf_media }) => ({
        method: "PATCH",
        url: `/ttf-user-permissions/${id}`,
        body: {
          data: {
            attributes: {
              ttf_role,
              payout_admin,
              tf_media
            }
          }
        }
      }),
      invalidatesTags: (_result, _error, { email }) => [{ type: 'CustomersPermissions', email }]
    }),
    createUser: build.mutation<
      { message: string },
      { email: string; confirmed_email: string; first_name: string; last_name: string; country_id: string }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when creating user!", response),
      query: ({ email, confirmed_email, first_name, last_name, country_id }) => ({
        method: "POST",
        url: `/create-user`,
        body: {
          data: {
            attributes: {
              email,
              confirmed_email,
              first_name,
              last_name,
              country_id,
            },
          },
        },
      }),
    }),
  }),
})

export const {
  useGetUserQuery,
  useGetVerificationStatusQuery,
  useResetVerificationMutation,
  useGetUserPermissionsQuery,
  useEditUserPermissionsMutation,
  useEditTTFUserPermissionsMutation,
  useGetVerificationManagementQuery,
  useUpdateBypassMutation,
  useCreateUserMutation,
} = customersApi
