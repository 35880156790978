import { FC } from "react"

import { useLogOutMutation } from "../../api/auth"
import { Button } from "../../components/Button"
import { customButtonContainer } from "../../components/Button/Button.css"
import { Header } from "../../components/Header"
import { catchError } from "../Home"

export const HeaderMenu: FC = () => {
    const [logOut, { isLoading }] = useLogOutMutation()
    return (
        <Header>
            <Button
                disabled={isLoading}
                onClick={async () => {
                    try {
                        await logOut().unwrap()
                        window.location.href = "/login"
                    } catch (e) {
                        catchError(e)
                    }
                }}
                className={customButtonContainer.containerHeaderLink}
                isLoading={isLoading}
                size="small"
                color="white"
            >
                Log Out
            </Button>
        </Header>
    )
}