import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { ClientReferralsResponse } from "../screens/ClientReferrals/ClientReferralTypes"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"

export const referralsApi = createApi({
    reducerPath: "referralsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
        credentials: "include",
        headers: {
            "Accept": "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
            'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
        },
        prepareHeaders: async (headers) => {
            headers.set('X-TF-ECOMMERCE', getCookieByName('X-TF-ECOMMERCE'))
            return headers
        }
    }),
    tagTypes: ["Referrals"],
    endpoints: (build) => ({
        getClientReferrals: build.query<
            ClientReferralsResponse,
            void
        >({
            query: () => ({
                url: "/referrals",
            }),
            providesTags: () => [{ type: "Referrals" }],
        }),
    }),
})

export const {
    useGetClientReferralsQuery,
} = referralsApi